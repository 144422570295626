export const socialLinks = {
  twitter: "https://twitter.com/pixel_gallery_",
  facebook: "https://www.facebook.com/Pixel-Gallery-102265252451954",
  instagram: "https://www.instagram.com/thepixelgalleryapp/",
  blog: "https://blog.thepixelgallery.co",
  community: "https://community.thepixelgallery.co",
  youtube: "",
  pinterest: "https://pinterest.com/pixelgalleryapp",
  telegram: "",
}

export const contactEmails = {
  support: "support@thepixelgallery.co",
  contact: "tom@thepixelgallery.co",
}

export const teamLinks = [
  {
    // title: "CEO / Founder",
    title: "Founder",
    authorPhoto: {
      src: "https://pixelgallery-staging.s3.eu-west-2.amazonaws.com/media/user_1/profile_picture.jpg",
      srcSet: "https://pixelgallery-staging.s3.eu-west-2.amazonaws.com/media/user_1/profile_picture.jpg",
    },
    authorName: "Tomos Wells",
    social: [
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/in/tomos-wells/",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/tomjwells",
      },
    ],
  },
]

export const team = [
  {
    title: "UI/UX Designer",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x",
    },
    authorName: "Kate Segelson",
  },
  {
    title: "Web Backend Developer",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson@2x.jpg 2x",
    },
    authorName: "Alex Johnson",
  },
  {
    title: "Web Frontend Developer",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/valeria-kogan@2x.jpg 2x",
    },
    authorName: "Valeria Kogan",
  },
  {
    title: "CEO / Co-Founder",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x",
    },
    authorName: "Akachi Luccini",
  },
  {
    title: "CTO / Co-Founder",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x",
    },
    authorName: "Jack Smith",
  },
  {
    title: "Consultant",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x",
    },
    authorName: "Veronica Adams",
  },
]

export const companies = [
  {
    name: "Airbnb",
    logo: "https://assets.maccarianagency.com/the-front/logos/airbnb.svg",
  },
  {
    name: "Coinbase",
    logo: "https://assets.maccarianagency.com/the-front/logos/coinbase.svg",
  },
  {
    name: "Dribbble",
    logo: "https://assets.maccarianagency.com/the-front/logos/dribbble.svg",
  },
]

export const mapData = [
  {
    location: {
      y: 45.453211,
      x: 9.248383,
      address: "Via A.G. Alaimo 147, 55027",
    },
  },
  {
    location: {
      y: 45.419211,
      x: 9.021383,
      address: "Via Rocca de Baldi 95, 440368",
    },
  },
  {
    location: {
      y: 45.473211,
      x: 9.298383,
      address: "Via Firenze 134, 45588",
    },
  },
  {
    location: {
      y: 45.461211,
      x: 9.000383,
      address: "Via Cavour 29, 201558",
    },
  },
  {
    location: {
      y: 45.413211,
      x: 9.398383,
      address: "Via Bologna 33, 220156",
    },
  },
  {
    location: {
      y: 45.569211,
      x: 9.128383,
      address: "Vicolo Tre Marchetti 127, 350125",
    },
  },
  {
    location: {
      y: 45.483211,
      x: 9.148383,
      address: "Via Lombardi 146, 45830",
    },
  },
  {
    location: {
      y: 45.313211,
      x: 9.012383,
      address: "Via Guantai Nuovi 99, 56989",
    },
  },
]

export const gallery = [
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery3@2x.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery2@2x.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/coworking/place3.jpg",
      srcSet: "https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
]
