import axiosInstance from "@/axiosInstance"
import { cryptoCurrencies, fiatCurrencies } from "@/data/pricingData"
import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"

const initialState = {
  isAuthenticated: false,
  isRegistered: false,
  isInitialized: false,
  isLoggingIn: true,
  isLoggingOut: false,
  user: null,
  fiatCurrency: null,
  cryptoCurrency: null,
  currencyMode: "crypto",
  geo: null,
  crypto: null,
  notifications: null,
  feed: [],
  feedIsLoaded: false,
  feedList: [],
  referrals: [],
  referralsIsLoaded: false,
  isNamiConnected: false,
  errors: [],
}

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    initialize(state, action) {
      const { isAuthenticated, isLoggingIn, user, geo, crypto, fiatCurrency, cryptoCurrency, currencyMode, isNamiConnected } = action.payload
      state.isAuthenticated = isAuthenticated
      state.isLoggingIn = isLoggingIn
      state.isInitialized = true
      state.user = user
      state.geo = geo
      state.crypto = crypto
      state.fiatCurrency = fiatCurrency
      state.cryptoCurrency = cryptoCurrency
      state.currencyMode = currencyMode
      state.isNamiConnected = isNamiConnected
      console.log(user)
    },
    connectWallet(state, action) {
      const { isNamiConnected } = action.payload
      state.isNamiConnected = isNamiConnected
    },
    login(state, action) {
      const { user, access_token, refresh_token, email_verification } = action.payload
      localStorage.setItem("access_token", access_token)
      localStorage.setItem("refresh_token", refresh_token)
      if (email_verification) {
        localStorage.setItem("email_verification", email_verification)
      }
      state.isAuthenticated = true
      state.user = user
      window.location.replace("/")
    },
    login_error(state, action) {
      state.isAuthenticated = false
      state.errors = action.payload
    },
    logout(state) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      axiosInstance.defaults.headers["Authorization"] = null
      state.isAuthenticated = false
      state.isInitialized = false
      state.isLoggingOut = true
      state.user = null
    },
    createUser: (state, action) => {
      console.log("register_success")
      const { user } = action.payload
      state.user = user
      state.isRegistered = true
      // window.location.href = "/signin/"
    },
    createUser_error(state, action) {
      state.isAuthenticated = false
      state.errors = action.payload // setting the error
    },
    updateUser(state, action) {
      console.log("updateUser")
      state.user = action.payload
    },
    updateCurrency(state, action) {
      const { fiatCurrency, cryptoCurrency, currencyMode } = action.payload
      console.log({ fiatCurrency, cryptoCurrency, currencyMode })
      state.fiatCurrency = fiatCurrency
      state.cryptoCurrency = cryptoCurrency
      state.currencyMode = currencyMode
    },
    updateUser_error(state, action) {
      console.log("updateUser_error")
      state.error = action.payload
    },
    updateUserImage(state, action) {
      state.user = action.payload
    },
    updateUserImage_error(state, action) {
      state.error = action.payload
    },
    updateNotifications(state, action) {
      state.notifications = action.payload
    },
    showFeed(state, action) {
      // console.log("action.payload", action.payload)
      state.feed = action.payload
      state.feedIsLoaded = true
      state.feedList = [...state.feedList, ...action.payload.results]
    },
    showFeed_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    listReferrals(state, action) {
      state.referrals = action.payload
      state.referralsIsLoaded = true
    },
    listReferrals_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
    },
  },
})

export const { reducer } = slice

export const login = (formState, setLoading) => async (dispatch) => {
  console.log("Login!")

  axiosInstance
    .post(`/users/jwt/obtain/`, {
      email: formState.values.email,
      password: formState.values.password,
    })
    .then((res) => {
      // console.log(res)
      // console.log(res.data)
      axiosInstance.defaults.headers["Authorization"] = "JWT " + res.data.access
      axiosInstance.get(`/users/user/`).then((userResponse) => {
        let user = userResponse.data
        dispatch(
          slice.actions.login({
            user,
            access_token: res.data.access,
            refresh_token: res.data.refresh,
          })
        )
        setLoading && setLoading(false)
      })
    })
    .catch((error) => {
      console.log("LOGIN_ERROR")
      console.log(error)
      console.log(error.response)
      console.log(error.response.data)
      if (typeof error.response !== "undefined" && setLoading) {
        setLoading(false) // It's more helpful if it keeps spinning when you can't connect because then you know it's not connecting
      }
      dispatch(slice.actions.login_error(error.response.data))
    })
}

export const verifyEmail = (token, setAwaitingResponse) => async (dispatch) => {
  // const access_token = await authApi.register({ email, name, password });
  // const user = await authApi.me(access_token);
  // localStorage.setItem('access_token', access_token);
  console.log("verifyEmail slice")

  const response = await axiosInstance
    .get(`/users/email-verification/?token=${token}`)
    .then(async (res) => {
      // setAwaitingResponse(false)
      console.log(res.data)
      console.log(res.data.message)
      // Performs a login
      console.log("Logging in...")
      axiosInstance.defaults.headers["Authorization"] = "JWT " + res.data.access
      axiosInstance.get(`/users/user/`).then((userResponse) => {
        let user = userResponse.data

        dispatch(
          slice.actions.login({
            user,
            access_token: res.data.access,
            refresh_token: res.data.refresh,
            email_verification: true,
          })
        )
      })
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      setAwaitingResponse(false)
      // dispatch(slice.actions.verifyEmail_error(error.response.data))
    })
}

export const logout = () => (dispatch) => {
  console.log("logout")

  // console.log(localStorage.getItem('refresh_token'))
  const response = axiosInstance.post("/users/jwt/blacklist/", {
    refresh_token: localStorage.getItem("refresh_token"),
  })
  dispatch(slice.actions.logout(response.data))
  window.location.replace("/")
}
export const contactEmail = (data, setLoading, setFormState, handleBottombarClose, initialFieldValues) => (dispatch) => {
  console.log("contactEmail")

  // console.log(localStorage.getItem('refresh_token'))
  const response = axiosInstance
    .post("/utils/emails/contact-us", data)
    .then((res) => {
      setLoading(false)
      setFormState(initialFieldValues)
      handleBottombarClose()
      toast.success("Message submitted! Thank you. We'll respond to your message ASAP.", {
        position: "top-center",
        autoClose: 7500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
    .catch((error) => {
      setLoading(false)
      console.log(error.response)
      console.log(error.response.data)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
}
export const create = (formState, setLoading) => async (dispatch) => {
  var user
  // const access_token = await authApi.register({ email, name, password });
  // const user = await authApi.me(access_token);

  // localStorage.setItem('access_token', access_token);
  console.log("create slice")

  const response = await axiosInstance
    .post(`/users/`, formState.values)
    .then((res) => {
      user = res.data
      dispatch(slice.actions.createUser({ user }))
      setLoading && setLoading(false)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      localStorage.removeItem("access_token") // There's a chance the user has wrong/old tokens and that causes a 401 error from the server, so clear them out here in case
      localStorage.removeItem("refresh_token")
      if (typeof error.response !== "undefined" && setLoading) {
        setLoading(false) // It's more helpful if it keeps spinning when you can't connect because then you know it's not connecting
      }
      dispatch(slice.actions.createUser_error(error.response.data))
    })
}

export const update = (formData, user, setEditing) => async (dispatch) => {
  console.log("User update")
  console.log(formData)
  // console.log("formValues", formData.values)
  // console.log("user", user)
  axiosInstance
    .patch(`/users/${user.id}/`, formData.values)
    .then((res) => {
      console.log("success1")
      user = res.data
      console.log("success2")
      if (setEditing) {
        setEditing(false)
      }
      console.log("success3")
      dispatch(slice.actions.updateUser(user))
      console.log("success4")
      console.log(user)
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}

export const updateCurrency = (fiatCurrency, cryptoCurrency, currencyMode) => async (dispatch) => {
  dispatch(slice.actions.updateCurrency({ fiatCurrency: fiatCurrency, cryptoCurrency: cryptoCurrency, currencyMode: currencyMode }))
  localStorage.setItem("fiatCurrency", fiatCurrency.code)
  localStorage.setItem("cryptoCurrency", cryptoCurrency.code)
  localStorage.setItem("currencyMode", currencyMode)
}

export const requestPasswordReset = (formData) => async (dispatch) => {
  console.log("requestPasswordReset")
  console.log(formData)

  axiosInstance
    .post(`/users/password-reset-request/`, formData.values)
    .then((res) => {
      // dispatch(slice.actions.updateUser(user))
      toast.success(res.data.success, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => window.location.replace("/"),
      })
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}
export const resetPassword = (formData) => async (dispatch) => {
  console.log("resetPassword")
  console.log(formData)

  axiosInstance
    .patch(`/users/password-reset/`, formData.values)
    .then((res) => {
      var user = res.data
      console.log(user)
      window.location.replace("/signin")
      localStorage.setItem("password_reset", true)
      dispatch(slice.actions.updateUser(user))
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}

export const connect_stripe = (formValues, setLoading) => async (dispatch) => {
  axiosInstance
    .post(`/users/create_stripe_connect_account/`, formValues)
    .then((res) => {
      console.log(res.data.url)
      window.location.replace(res.data.url)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      setLoading && setLoading(false)
    })
}

export const showFeed = (page, page_size, setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/products/feed?page=${page}&page_size=${page_size}`)
    .then((res) => {
      // console.log("showFeed:",res.data)
      setLoading && setLoading(false)
      dispatch(slice.actions.showFeed(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showFeed_error(err.response.data))
    })
}
export const listReferrals = (setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/users/referrals`)
    .then((res) => {
      console.log("listReferrals:", res.data)
      setLoading && setLoading(false)
      dispatch(slice.actions.listReferrals(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showFeed_error(err.response.data))
    })
}

export const getConversion = async (crypto, fiat) => {
  return await axiosInstance
    .get(`/utils/crypto/prices?crypto=${crypto}&fiat=${fiat}`)
    .then((res) => res?.data.price)
    .catch((error) => {
      // handle error
      console.log(error)
    })
}

export const initializeUser = () => async (dispatch) => {
  let crypto = {
    ADA: {
      USD: await getConversion("ADA", "USDT"),
      GBP: await getConversion("ADA", "GBP"),
      AUD: await getConversion("ADA", "AUD"),
      EUR: await getConversion("ADA", "EUR"),
    },
  }
  let geo = await axios
    .get("/geo/")
    .then((response) => {
      let data = response.data
      return {
        country: {
          name: data.country_name,
          code: data.country_code,
        },
        currency: data.currency,
      }
    })
    .catch((error) => {
      console.log(error)
      return null
    })
  let isNamiConnected = await window.cardano?.nami?.isEnabled().then((enabled) => {
    return enabled
  })

  if (!localStorage.getItem("fiatCurrency")) {
    var fiatCurrency = fiatCurrencies.filter((c) => c.code === geo.currency)[0]
  } else {
    var fiatCurrency = fiatCurrencies.filter((c) => c.code === localStorage.getItem("fiatCurrency"))[0]
  }
  if (!localStorage.getItem("cryptoCurrency")) {
    var cryptoCurrency = cryptoCurrencies[0]
  } else {
    var cryptoCurrency = cryptoCurrencies.filter((c) => c.code === localStorage.getItem("cryptoCurrency"))[0]
  }
  if (!localStorage.getItem("currencyMode")) {
    var currencyMode = initialState.currencyMode
  } else {
    var currencyMode = localStorage.getItem("currencyMode")
  }

  try {
    const access_token = window.localStorage.getItem("access_token")

    if (access_token) {
      // Get the curent user
      axiosInstance
        .get(`/users/user/`)
        .then((userResponse) => {
          dispatch(
            slice.actions.initialize({
              isAuthenticated: true,
              isLoggingIn: false,
              user: userResponse.data,
              geo: geo,
              crypto: crypto,
              fiatCurrency: fiatCurrency,
              cryptoCurrency: cryptoCurrency,
              currencyMode: currencyMode,
              isNamiConnected,
            })
          )
        })
        .catch((error) => {
          dispatch(slice.actions.initialize({ isAuthenticated: false, user: null, geo: geo, crypto: crypto, fiatCurrency: fiatCurrency, cryptoCurrency: cryptoCurrency, currencyMode: currencyMode }))
        })
    } else {
      dispatch(
        slice.actions.initialize({
          isAuthenticated: false,
          isLoggingIn: false,
          user: null,
          geo: geo,
          crypto: crypto,
          fiatCurrency: fiatCurrency,
          cryptoCurrency: cryptoCurrency,
          currencyMode: currencyMode,
        })
      )
    }
  } catch (err) {
    console.log("Error")
    console.log(err)
    dispatch(slice.actions.initialize({ isAuthenticated: false, user: null, geo: geo, crypto: crypto }))
  }
}
export const connectWallet = () => async (dispatch) => {
  window.cardano?.nami.enable().then(() => {
    // setNamiConnected(true)
    dispatch(
      slice.actions.connectWallet({
        isNamiConnected: true,
      })
    )
  })
}
export const sendReferralEmails = (formState, setLoading, onClose) => async (dispatch) => {
  axiosInstance
    .post(`/users/referrals/send_referral_email/`, formState.values)
    .then((res) => {
      console.log("listReferrals:", res.data)
      setLoading && setLoading(false)
      onClose && onClose()
      dispatch(slice.actions.foo(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showFeed_error(err.response.data))
    })
}

export default slice
