import { useDispatch, useSelector } from "@/reducerStore"
import { initializeUser } from "@/slices/users"
import getTheme from "@/theme"
import { Paper } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, useTheme } from "@mui/material/styles"
import AOS from "aos"
import "aos/dist/aos.css"
import React, { useEffect, useState } from "react"

export const useDarkMode = () => {
  const [themeMode, setTheme] = useState("light")
  const [mountedComponent, setMountedComponent] = useState(false)

  const setMode = (mode) => {
    window.localStorage.setItem("themeMode", mode)
    setTheme(mode)
  }

  const themeToggler = () => {
    themeMode === "light" ? setMode("dark") : setMode("light")
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem("themeMode")
    localTheme ? setTheme(localTheme) : setMode("light")
    setMountedComponent(true)
    AOS.refresh()
  }, [])

  useEffect(() => {
    AOS.refresh()
  }, [themeMode])

  return [themeMode, themeToggler, mountedComponent]
}

export default function WithLayout({ component: Component, layout: Layout, privateRoute, backgroundColor = "white", padding = "0", ...rest }) {
  const [dispatched, setDispatched] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: "ease-in-out",
    })
  }, [])

  const [themeMode, themeToggler, mountedComponent] = useDarkMode()
  useEffect(() => {
    AOS.refresh()
  }, [mountedComponent])

  const dispatch = useDispatch()
  useEffect(() => {
    if (!dispatched) {
      dispatch(initializeUser())
      setDispatched(true)
      // console.log("dispatched:", dispatched)
    }
  }, [dispatch, dispatched])

  // Whether to use the secondary background color or not
  // If the backgroundColor argument
  backgroundColor = themeMode === "light" ? backgroundColor : theme.palette.background.level0

  var { isInitialized, isAuthenticated, errors } = useSelector((state) => state.user)
  if (isInitialized && privateRoute && !isAuthenticated) {
    console.log("redirect")
    // not logged in so redirect to login page with the return url
    window.location.replace("/signin")
  } else {
    return (
      <ThemeProvider theme={getTheme(themeMode)}>
        <CssBaseline />
        <Paper elevation={0}>
          <Layout themeMode={themeMode} backgroundColor={backgroundColor} padding={padding}>
            <Component themeMode={themeMode} themeToggler={themeToggler} {...rest} />
          </Layout>
        </Paper>
      </ThemeProvider>
    )
  }
}
