import storeTPGReferralCode from "@/utils/storeTPGReferralCode.js"
import usePageTracking from "@/utils/usePageTracking.js"
import useTPGPageTracking from "@/utils/useTPGPageTracking.js"
import { useRoutes } from "react-router-dom"

import routes from "./Routes"

function App() {
  usePageTracking()
  useTPGPageTracking()
  storeTPGReferralCode()

  return useRoutes(routes)
}

export default App
