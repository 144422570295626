import { useSelector } from "@/reducerStore"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import AppBar from "@mui/material/AppBar"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import Toolbar from "@mui/material/Toolbar"
import { makeStyles, useTheme } from "@mui/styles"
import React from "react"
import { LHSMenu, RHSLinks } from "./Links"
import SearchBar from "./SearchBar"

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    textAlign: "right",
  },
  toolbar: {
    zIndex: 999,
    // maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: 0,
    // padding: theme.spacing(0, 2),
    background: "transparent",
    // [theme.breakpoints.up("sm")]: {
    //   padding: theme.spacing(0, 8),
    // },
    borderWidth: "1px",
    border: "#DDDDDD",
    borderBottomStyle: "solid",
  },
  navLink: {
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: "pointer",
    "&:hover > .menu-item, &:hover svg": {
      color: theme.palette.primary.dark,
    },
    "&.menu-item--no-dropdown": {
      paddingRight: 0,
    },
  },
  listItemActive: {
    "&> .menu-item": {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  listItemButton: {
    whiteSpace: "nowrap",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: "0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)",
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 200,
    height: 40,
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 60,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    marginRight: theme.spacing(5),
    "&:last-child": {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: "uppercase",
  },
  topbarButtonSelected: {
    justifyContent: "center",
    backgroundColor: theme.palette.button.light,
    "&:hover": {
      backgroundColor: theme.palette.button.dark,
    },
  },
  topbarButtonUnselected: {
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.button.dark,
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  start: {
    flex: 1 /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */,
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      minWidth: "100px",
    },
  },
  center: { display: "flex", flexDirection: "row", alignItems: "center", flex: "0 1 416px", minWidth: "0px", justifyContent: "center", paddingLeft: "8px", paddingRight: "8px" },
  end: {
    // minWidth: "430px" /* min-width necessary for when logged in */,
    // [theme.breakpoints.down("md")]: {
    //   minWidth: "225px",
    // },
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
}))

export default function Topbar({ themeMode, onSidebarOpen, className, ...rest }) {
  const theme = useTheme()
  const classes = useStyles()
  const [miniSearchActive, setMiniSearchActive] = React.useState(false)

  var { user, errors, isAuthenticated, isInitialized } = useSelector((state) => state.user)

  return (
    <AppBar position="static" className={classes.toolbar} {...rest}>
      <Container maxWidth={"xl"} style={{ paddingLeft: "16px", paddingRight: "16px" }}>
        <Toolbar className={classes.container} disableGutters>
          {!miniSearchActive ? (
            <div className={classes.start}>
              <LHSMenu />
            </div>
          ) : (
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search" onClick={(prevState) => setMiniSearchActive((prevState) => !prevState)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <div className={classes.center}>
            <SearchBar miniSearchActive={miniSearchActive} setMiniSearchActive={setMiniSearchActive} />
          </div>
          <div className={classes.end}>
            <List disablePadding className={classes.navigationContainer} style={{ justifyContent: "flex-end" }}>
              {!miniSearchActive && isInitialized ? <RHSLinks /> : <div />}
            </List>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
