import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  buildinginpublic: {},
  errors: [],
}

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    listbuildinginpublic(state, action) {
      state.buildinginpublic = action.payload
    },
  },
})

export const { reducer } = slice

export const getbuildinginpublic = () => async (dispatch) => {
  axiosInstance.get(`/metrics/buildinginpublic/1`).then((res) => {
    dispatch(slice.actions.listbuildinginpublic(res.data))
  })
}

export default slice
