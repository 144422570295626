import { images } from "@/data/assets"
import { socialLinks } from "@/data/links"
import { useSelector } from "@/reducerStore"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import PinterestIcon from "@mui/icons-material/Pinterest"
import TwitterIcon from "@mui/icons-material/Twitter"
import { Button, Grid, IconButton, List, ListItem, Typography, useMediaQuery } from "@mui/material"
import { makeStyles, useTheme } from "@mui/styles"
import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Logo } from "../Logo"
import CurrencySwitcher from "./CurrencySwitcher"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 120,
    height: 36,
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  groupTitle: {
    textTransform: "uppercase",
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: "rgba(255,255,255,.6)",
    "&:hover": {
      background: "transparent",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: "0 !important",
  },
  menu: {
    display: "flex",
  },
  menuItem: {
    margin: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: "uppercase",
    color: "white",
  },
  divider: {
    width: "100%",
  },
  navLink: {
    color: "rgba(255,255,255,.6)",
    textDecoration: "none",
  },
}))

const Footer = (props) => {
  const { pages, className, ...rest } = props

  const classes = useStyles()

  const landings = pages.landings
  const supportedPages = pages.pages
  const account = pages.account

  const MenuGroup = (props) => {
    const { item, label } = props
    var { user, isAuthenticated } = useSelector((state) => state.user)

    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) =>
          page.external ? (
            <ListItem disableGutters key={i} className={classes.menuGroupItem}>
              <Typography variant="body2" component={"a"} href={page.href} target="_blank" className={clsx(classes.navLink, "submenu-item")}>
                {page.title}
              </Typography>
            </ListItem>
          ) : (
            <ListItem disableGutters key={i} className={classes.menuGroupItem}>
              <RouterLink variant="body2" to={page.href} className={clsx(classes.navLink, "submenu-item")}>
                <Typography variant="body2" className={clsx(classes.navLink, "submenu-item")}>
                  {page.title}
                </Typography>
              </RouterLink>
            </ListItem>
          )
        )}
        {isAuthenticated && label === "company" && (
          <>
            <ListItem disableGutters className={classes.menuGroupItem} style={{ paddingTop: "5px" }}>
              <RouterLink variant="body2" to={"/referrals"} className={clsx(classes.navLink, "submenu-item")}>
                <Button size="small" variant="outlined" color="secondary" className={classes.listItemButton}>
                  Refer a Friend
                </Button>
              </RouterLink>
            </ListItem>
          </>
        )}
      </List>
    )
  }

  const SupportedPages = () => {
    const { company, contact, blog } = supportedPages.children
    return (
      <div className={classes.menu}>
        <div>
          {/* <MenuGroup item={career} /> */}
          {/* <MenuGroup item={helpCenter} /> */}
        </div>
        <div>
          <MenuGroup item={company} label={"company"} />
          <List disablePadding className={classes.menuItem}></List>
        </div>
        <div>
          <MenuGroup item={contact} />
        </div>
        <div>
          <MenuGroup item={blog} />
          {/* <MenuGroup item={portfolio} /> */}
        </div>
      </div>
    )
  }
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  })

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={4} alignItems="center" justifyContent="center" style={{ textAlign: isMd ? "" : "center" }}>
            <List disablePadding style={{ display: "inline-block" }}>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <RouterLink to={"/"}>
                  <Logo classes={classes} image={images.thePixelGallery.bannerDark} />
                </RouterLink>
                {/* <RouterLink to="/" title="thepixelgallery">
                    <Image className={classes.logoImage} src={images.thePixelGallery.bannerDark} alt="The Pixel Gallery" lazy={true} />
                  </RouterLink> */}
              </ListItem>
              <ListItem disableGutters>
                <IconButton className={classes.socialIcon} href={socialLinks.facebook} target="_blank">
                  <FacebookIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon} href={socialLinks.instagram} target="_blank">
                  <InstagramIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon} href={socialLinks.twitter} target="_blank">
                  <TwitterIcon className={classes.icon} />
                </IconButton>
                <IconButton className={classes.socialIcon} href={socialLinks.pinterest} target="_blank">
                  <PinterestIcon className={classes.icon} />
                </IconButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={8} md={6} className={isSm ? classes.menuListContainer : ""}>
            <Grid container spacing={0}>
              {/* <Grid item>
                <LandingPages />
              </Grid> */}
              <Grid item>
                <SupportedPages />
              </Grid>
              {/* <Grid item>
                <AccountPages />
              </Grid> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              alignItems="center"
              justifyContent="center"
              sx={{ p: 5 }}
              // style={{ textAlign: "center" }}
            >
              <CurrencySwitcher />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={2} className={isMd ? classes.menuListContainer : ""}>
            {/* <TrustBadge /> */}
            {/* <BlockchainLogos height={30} /> */}
            {/* <BuildInPublic /> */}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
}

export default Footer
