import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import Tooltip from "@mui/material/Tooltip"
import React from "react"

import clsx from "clsx"

import { images } from "@/data/assets"
import { useDispatch, useSelector } from "@/reducerStore"
import MenuIcon from "@mui/icons-material/Menu"
import { useMediaQuery } from "@mui/material"
import { makeStyles, useTheme } from "@mui/styles"
import { Link as RouterLink } from "react-router-dom"

import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import NamiButton from "../../../../../Transactions/BrowserExtensionWallets/Nami/NamiButton"
import { Logo } from "../Logo"
import NotificationsMenu from "./NotificationsMenu"
import UserMenu from "./UserMenu"

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    zIndex: 999,
    // maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    background: "transparent",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
    borderWidth: "1px",
    border: "#DDDDDD",
    borderBottomStyle: "solid",
  },
  navRouterLink: {
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    justifyContent: "center",
    padding: "8px",
    cursor: "pointer",
    "&:hover > .menu-item, &:hover svg": {
      color: theme.palette.primary.dark,
    },
  },
  listItemActive: {
    "&> .menu-item": {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  listItemButton: {
    whiteSpace: "nowrap",
  },
  listItemIcon: {
    minWidth: "auto",
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: "0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)",
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 200,
    height: 40,
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 60,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    marginRight: theme.spacing(5),
    "&:last-child": {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: "uppercase",
  },
  topbarButtonSelected: {
    justifyContent: "center",
    backgroundColor: theme.palette.button.light,
    "&:hover": {
      backgroundColor: theme.palette.button.dark,
    },
  },
  topbarButtonUnselected: {
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.button.dark,
    },
  },
}))

export function SignInButton({}) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")} style={{ width: "inherit" }}>
      <Button variant="outlined" size="large" color="primary" component={RouterLink} to="/signin" className={classes.listItemButton}>
        Log In
      </Button>
    </ListItem>
  )
}
export function SignUpButton({ text }) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")}>
      <Button variant="contained" size="large" color="primary" component={RouterLink} to="/signup" className={classes.listItemButton} style={{ backgroundColor: "#0b5ac1" }}>
        {text ? text : "Sign Up"}
      </Button>
    </ListItem>
  )
}

//
export function BecomeATPGArtist({}) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")} style={{ width: "inherit" }}>
      <Button size="large" variant="outlined" color="info" component={RouterLink} to="/become-a-tpg-artist" className={classes.listItemButton}>
        Become a TPG Artist
      </Button>
    </ListItem>
  )
}
function AddNewArtwork({}) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")} style={{ width: "inherit" }}>
      <Tooltip title="Add New Artwork">
        <RouterLink to="/artworks/new" style={{ textDecoration: "none" }}>
          {/* <Icon baseClassName="far" className="fa-plus-square" sx={{ fontSize: 35}} /> */}
          {/* <Icon sx={{ fontSize: 35}} style={{ overflow: "visible" }}>
              <img src={"https://cdn.iconscout.com/icon/free/png-256/art-museum-painting-palette-38814.png"} height={35} width={35} style={{ maxWidth: 160 }} alt="Add New Artwork" />
            </Icon> */}
          <Button size="large" variant="outlined" color="secondary" className={classes.listItemButton}>
            Add New Artwork
          </Button>
        </RouterLink>
      </Tooltip>
    </ListItem>
  )
}
function Referrals({}) {
  const classes = useStyles()

  return (
    <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")} style={{ width: "inherit" }}>
      <Tooltip title="Refer a Friend">
        <RouterLink to="/referrals" style={{ textDecoration: "none" }}>
          {/* <Icon baseClassName="far" className="fa-plus-square" sx={{ fontSize: 35}} /> */}
          {/* <Icon sx={{ fontSize: 35}} style={{ overflow: "visible" }}>
              <img src={"https://cdn.iconscout.com/icon/free/png-256/art-museum-painting-palette-38814.png"} height={35} width={35} style={{ maxWidth: 160 }} alt="Add New Artwork" />
            </Icon> */}
          <Button size="large" variant="outlined" color="secondary" className={classes.listItemButton}>
            Refer a Friend
          </Button>
        </RouterLink>
      </Tooltip>
    </ListItem>
  )
}

function LargeMenu({ pages, classes, isAuthenticated }) {
  return (
    <>
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        <RouterLink to={"/"} style={{ textDecoration: "none", paddingLeft: "8px", paddingRight: "8px" }}>
          <Logo classes={classes} image={images.thePixelGallery.bannerLight} />
        </RouterLink>
        <div sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} style={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", paddingLeft: "8px", paddingRight: "8px" }}>
          {pages.map((page) =>
            page.onlyShowIfSignedIn && !isAuthenticated ? null : (
              <RouterLink to={page.href} style={{ textDecoration: "none" }} key={page.title}>
                <Button sx={{ my: 2, display: "block" }} color={"primary"} className={page.href === window.location.pathname ? classes.topbarButtonSelected : classes.topbarButtonUnselected}>
                  {page.title}
                </Button>
              </RouterLink>
            )
          )}
        </div>
      </Box>
    </>
  )
}

function SwipeableTemporaryDrawer({ pages, classes, isMd, isAuthenticated }) {
  const [state, setState] = React.useState({
    left: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  // console.log("isMd")
  // console.log(isMd)

  const list = (anchor) => (
    <>
      <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
        <List>
          <ListItem className={clsx(classes.listItem, "menu-item--no-dropdown")}>
            <Logo classes={classes} image={images.thePixelGallery.bannerLight} />
          </ListItem>
          {/* <Divider /> */}
          {isAuthenticated && !isMd && <AddNewArtwork classes={classes} />}
          {/* {isAuthenticated && !isMd && <Referrals classes={classes} />} */}
          {/* {!isAuthenticated && !isMd && <SignInSignUpButtons classes={classes}/>} */}
          {!isAuthenticated && !isMd && <BecomeATPGArtist classes={classes} />}
          {pages.map((page, index) =>
            page.onlyShowIfSignedIn && !isAuthenticated ? null : (
              <ListItem key={index} disablePadding component={RouterLink} to={page.href} style={{ textDecoration: "none", color: "black" }}>
                <ListItemButton>
                  {/* <ListItemIcon>
                {index % 2 === 0 ? <InboCloseIcon /> : <MailIcon />}
              </ListItemIcon> */}
                  <ListItemText primary={page.title} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboCloseIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      </Box>
    </>
  )

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "#333333" }} />
          </IconButton>
          <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}

export function LHSMenu({ themeMode, onSidebarOpen, className, ...rest }) {
  var { user, errors, isAuthenticated, isInitialized } = useSelector((state) => state.user)

  const theme = useTheme()
  var isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })
  const classes = useStyles()

  var pages = [
    {
      title: "Home",
      href: "/",
    },
    // {
    //   title: "Profile",
    //   href: "/profile/" + user?.id
    // },
    {
      title: "Artists",
      href: "/artists",
    },
    {
      title: "Wallet",
      href: "/wallet",
      onlyShowIfSignedIn: true,
    },
    {
      title: "Feed",
      href: "/feed",
    },
  ]

  const LHSMenu = isMd ? (
    <LargeMenu pages={pages} classes={classes} images={images} isAuthenticated={isAuthenticated} />
  ) : (
    <SwipeableTemporaryDrawer isMd={isMd} isAuthenticated={isAuthenticated} pages={pages} classes={classes} images={images} />
  )

  return LHSMenu
}

export function RHSLinks({ themeMode, onSidebarOpen, className, ...rest }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  var isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  var { user, errors, isAuthenticated, isInitialized } = useSelector((state) => state.user)

  return isAuthenticated ? (
    <>
      {isMd && <NamiButton />}
      {isMd && <AddNewArtwork />}
      {/* {isMd && <Referrals classes={classes} />} */}
      <NotificationsMenu user={user} notifications={user?.notifications} />
      <UserMenu user={user} />
    </>
  ) : (
    <>
      {isMd && <BecomeATPGArtist />}
      <SignInButton />
      <SignUpButton />
    </>
  )
}
