import { cryptoCurrencies, fiatCurrencies } from "@/data/pricingData"
import { useDispatch, useSelector } from "@/reducerStore"
import { updateCurrency } from "@/slices/users"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import * as React from "react"
import CurrencySwitcherPopup from "./CurrencySwitcherPopup"
const theme = createTheme({
  palette: {
    neutral: {
      main: "rgba(255,255,255,.7)",
      contrastText: "rgba(255,255,255,.1)",
    },
  },
})

export default function CurrencySwitcher() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const dispatch = useDispatch()
  var { user, fiatCurrency, cryptoCurrency, currencyMode } = useSelector((state) => state.user)

  const handleClick = () => {
    var cryptoCurrency = cryptoCurrencies[0]
    dispatch(updateCurrency(fiatCurrency, cryptoCurrency, "crypto"))
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    var fiatCurrency = fiatCurrencies[index]
    console.log("selected fiatCurrency", fiatCurrency)
    console.log("selected cryptoCurrency", cryptoCurrency)
    dispatch(updateCurrency(fiatCurrency, cryptoCurrency, "fiat"))
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    fiatCurrency &&
    cryptoCurrency && (
      <React.Fragment>
        <CurrencySwitcherPopup />
        <ThemeProvider theme={theme}>
          <ButtonGroup color="neutral" ref={anchorRef} aria-label="split button">
            <Button
              style={{ textTransform: "none", fontSize: "1.1rem", cursor: "inherit", borderColor: "rgba(255,255,255,0.5)" }}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              disableRipple
              disableFocusRipple
              disableElevation
            >
              Currency Switcher:
            </Button>
            <Button
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{ fontSize: "1.1rem", backgroundColor: currencyMode === "fiat" && "rgba(255,255,255,0.12)" }}
            >
              {fiatCurrency?.code} ({fiatCurrency?.symbol})
              <ArrowDropDownIcon />
            </Button>
            <Button onClick={handleClick} style={{ fontSize: "1.1rem", backgroundColor: currencyMode === "crypto" && "rgba(255,255,255,0.12)" }}>
              {cryptoCurrency?.code} ({cryptoCurrency?.symbol})
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {fiatCurrencies.map((currency, index) => (
                        <MenuItem key={index} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                          {currency.code} ({currency.symbol})
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ThemeProvider>
      </React.Fragment>
    )
  )
}
