import Popup from "@/components/custom/Popup/Popup"
import { cryptoCurrencies, fiatCurrencies } from "@/data/pricingData"
import { useDispatch, useSelector } from "@/reducerStore"
import { updateCurrency } from "@/slices/users"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import Typography from "@mui/material/Typography"
import * as React from "react"

export default function CurrencySwitcherPopup() {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const dispatch = useDispatch()
  var { user, fiatCurrency, cryptoCurrency, currencyMode } = useSelector((state) => state.user)
  const borderRadius = "30px"

  const handleClick = () => {
    dispatch(updateCurrency(fiatCurrency, cryptoCurrencies[0], "crypto"))
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    dispatch(updateCurrency(fiatCurrencies[index], cryptoCurrency, "fiat"))
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // const [openPopup, setOpenPopup] = React.useState(!localStorage.getItem("currencyMode"))
  const [openPopup, setOpenPopup] = React.useState(!currencyMode)

  return (
    <Popup openPopup={openPopup} setOpenPopup={null} noDividers={true}>
      <Box style={{ width: "100%", textAlign: "center" }}>
        <Typography color="textSecondary" align="center" style={{ fontSize: "1.2rem" }}>
          <em>Select a currency to view our artworks</em>
        </Typography>
        <Typography color="textSecondary" align="center" style={{ fontSize: "1.2rem" }}>
          <em>(The currency can be changed later from the website's footer)</em>
        </Typography>
        <br />
        <ButtonGroup
          color="primary"
          aria-label="split button"
          style={{
            width: "90%",
          }}
        >
          <Button
            size="large"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            ref={anchorRef}
            style={{
              fontSize: "4rem",
              backgroundColor: currencyMode === "fiat" && "rgba(1,51,173,0.2)",
              width: "50%",
              height: "40vh",
              borderWidth: "2px",
              borderTopLeftRadius: borderRadius,
              borderBottomLeftRadius: borderRadius,
            }}
          >
            {fiatCurrency?.code} ({fiatCurrency?.symbol})
            <ArrowDropDownIcon fontSize="large" />
          </Button>
          <Button
            onClick={handleClick}
            style={{
              width: "50%",
              borderWidth: "2px",
              borderTopRightRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              fontSize: "4rem",
              backgroundColor: currencyMode === "crypto" && "rgba(1,51,173,0.2)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <img src="https://ucarecdn.com/b3841650-68f7-4376-8355-09a7c040151e/-/format/webp/-/resize/1500/" style={{ height: "200px", width: "200px" }} /> */}
            <img src="https://pixelgallery.s3.eu-west-2.amazonaws.com/static/assets/cardano_coin.png" style={{ height: "200px", width: "200px" }} />
            {cryptoCurrency?.code} ({cryptoCurrency?.symbol})
          </Button>
        </ButtonGroup>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          <MenuList id="split-button-menu" autoFocusItem>
            {fiatCurrencies.map((currency, index) => (
              <MenuItem key={index} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                {currency.code} ({currency.symbol})
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        {/* <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {fiatCurrencies.map((currency, index) => (
                      <MenuItem key={index} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                        {currency.code} ({currency.symbol})
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}
        <br />
      </Box>
      <br />
      {currencyMode && (
        <Box style={{ width: "100%", textAlign: "right" }}>
          <Button size="large" variant="contained" onClick={() => setOpenPopup(false)}>
            Ok
          </Button>
        </Box>
      )}
    </Popup>
  )
}
