export const fiatCurrencies = [
  {
    code: "USD",
    symbol: "$",
  },
  {
    code: "GBP",
    symbol: "£",
  },
  {
    code: "EUR",
    symbol: "€",
  },
  {
    code: "AUD",
    symbol: "A$",
  },
]
export const cryptoCurrencies = [
  {
    code: "ADA",
    symbol: "₳",
  },
]

export const pricings = [
  {
    title: "Free",
    subtitle: "The Pixel Gallery's free tier, accessible to all",
    monthly: 0,
    annual: 0,
    priceSuffix: " / MO",
    features: ["Create up to 25 tokens", "Register up to 5 scanning accounts"],
    // disclaimer: 'Fully featured 30-day free trial',
    isHighlighted: false,
  },
  {
    title: "Team",
    subtitle: "A pay-once license, just for you",
    monthly: 9,
    annual: 90,
    priceSuffix: " / MO",
    features: ["Create up to 1,000 tokens", "Register up to 10 scanning accounts", "Create up to 5 access groups", "CSV import/export of your identity tokens"],
    disclaimer: "",
    isHighlighted: false,
  },
  {
    title: "Business",
    subtitle: "A pay-once license, just for you",
    monthly: 97,
    annual: 970,
    priceSuffix: " / MO",
    features: ["Create up to 10,000 tokens", "Register up to 100 scanning accounts", "Create up to 50 access groups", "CSV import/export of your identity tokens"],
    disclaimer: "",
    isHighlighted: true,
  },
  {
    title: "Enterprise",
    subtitle: "A pay-once license, just for you",
    monthly: 497,
    annual: 4970,
    priceSuffix: " / MO",
    features: ["Unlimited Token Issuance", "Register unlimited scanning accounts", "Create an unlimited number of access groups", "CSV import/export of your identity tokens", "24/7 customer support"],
    disclaimer: "",
    isHighlighted: false,
  },
]

export const faq = [
  {
    id: "faq-1",
    title: "Flexible access to facilities.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
  {
    id: "faq-2",
    title: "Snacks, drinks, coffee, and more.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
  {
    id: "faq-3",
    title: "On site tech support.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
  {
    id: "faq-4",
    title: "Flexible access to facilities.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
  {
    id: "faq-5",
    title: "Snacks, drinks, coffee, and more.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
  {
    id: "faq-6",
    title: "On site tech support.",
    subtitle: "Our new key fobs make it so easy!",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.",
    link: "Check it out",
  },
]

export const plans = [
  {
    title: "Move deals through your funnel – fast",
    subtitle: "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
  {
    title: "On demand services right at your front-door",
    subtitle: "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
  {
    title: "Online 24/7 support",
    subtitle: "Our chatbots and live chat capture more of your best leads and convert them while they’re hot.",
  },
]
