import axiosInstance from "@/axiosInstance"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export default function usePageTracking() {
  const [searchParams, setSearchParams] = useSearchParams()
  const trackingParameter = "n"
  const [tracking_id, setTracking_id] = useState(searchParams.get(trackingParameter))

  useEffect(() => {
    // Remove the param
    if (searchParams.has(trackingParameter)) {
      searchParams.delete(trackingParameter);
      setSearchParams(searchParams);
    }
  },[])

  if(searchParams.get(trackingParameter)){
    var baseURL
    if (import.meta.env.PROD) {
      baseURL = window.location.protocol + "//genie." + window.location.host
    } else {
      baseURL = window.location.protocol + "//" + window.location.hostname + ":8004"
    }
    axiosInstance
      .post(`${baseURL}/emails/p/`, {"t_id": tracking_id })
      .then((res) => {
        // console.log("Tracking Succeeded")
      })
      .catch((err) => {
        console.log("Tracking Unsuccessful")
        console.log(err)
      })
  }


}
