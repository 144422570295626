import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  productsList: [],
  products: [],
  product: {}, // Should be an object rather than an array
  likedByList: [],
  errors: [],
}

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    initialize(state, action) {
      state.productsList = initialState.productsList
      state.products = initialState.products
      state.product = initialState.product
      state.likedByList = initialState.likedByList
      state.errors = initialState.errors
    },
    listProducts(state, action) {
      state.products = action.payload
      state.productsList = [...state.productsList, ...action.payload.results]
    },
    showProduct(state, action) {
      state.product = action.payload
    },
    showProduct_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createProduct(state, action) {
      state.product = action.payload
      state.errors = []
    },
    createProduct_error(state, action) {
      state.errors = action.payload
    },
    editProduct(state, action) {
      state.product = action.payload
    },
    updateProduct(state, action) {
      // listProducts()
      state.errors = []
    },
    updateProduct_error(state, action) {
      state.errors = action.payload
    },
    deleteProduct(state, action) {
      state.product = state.products.filter((Product, index) => Product.id !== action.payload.id)
      state.errors = []
    },
    getLikedBy(state, action) {
      state.likedByList = action.payload
      state.errors = []
    },
  },
})

export const { reducer } = slice

export const initializeProducts = (page, setLoading) => async (dispatch) => {
  dispatch(slice.actions.initialize())
}
export const listProducts = (page, setLoading) => async (dispatch) => {
  axiosInstance.get(`/products/?page=${page}`).then((res) => {
    console.log("listProducts", res.data)
    setLoading && setLoading(false)
    dispatch(slice.actions.listProducts(res.data))
  })
  .catch((err) => {
    // Give it one retry, because sometimes the lambda times out (but succeeds on the second attempt since it is now warm)
    axiosInstance.get(`/products/?page=${page}`).then((res) => {
      console.log("listProducts", res.data)
      setLoading && setLoading(false)
      dispatch(slice.actions.listProducts(res.data))
    })
    .catch((err) => {
      console.log(`/products/?page=${page} request failed, err: `,err)
    })
  })
}
export const searchProducts = (query, page, setLoading, similarity) => async (dispatch) => {
  axiosInstance.get(`/products/search?query=${query}&page=${page}${similarity ? `&similarity=${similarity}` : ""}`).then((res) => {
    dispatch(slice.actions.listProducts(res.data))
    // console.log("Search results:", res.data)
    setLoading && setLoading(false)
  })
}
export const showProduct = (pk) => async (dispatch) => {
  axiosInstance
    .get(`/products/${pk}`)
    .then((res) => {
      dispatch(slice.actions.showProduct(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showProduct_error(err.response?.data))
    })
}

export const createProduct = (formData, setOpenPopup, setLoading) => async (dispatch) => {
  axiosInstance
    .post(`/products/`, formData)
    .then((res) => {
      dispatch(slice.actions.createProduct(res.data))
      setOpenPopup(true)
      setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data)
      if (err.response.data.file) {
        toast.info(err.response.data.file[0], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      dispatch(slice.actions.createProduct_error(err.response.data))
      setLoading(false)
    })
}

export const editProduct = (products) => async (dispatch) => {
  dispatch(slice.actions.editProduct(products))
}

export const updateProduct = (formData, setOpenEditBrief, setOpenEditArtworkInformation, setOpenEditPrice, setLoading) => async (dispatch) => {
  console.log("formData")
  console.log(formData)
  axiosInstance
    .put(`/products/${formData.id}/`, formData)
    .then((res) => {
      setOpenEditBrief && setOpenEditBrief(false)
      setOpenEditArtworkInformation && setOpenEditArtworkInformation(false)
      setOpenEditPrice && setOpenEditPrice(false)
      setLoading && setLoading(false)
      dispatch(slice.actions.updateProduct(res.data))
      dispatch(showProduct(formData.id))
    })
    .catch((err) => {
      console.log(err)
      console.log(err.response.data)
      dispatch(slice.actions.updateProduct_error(err.response.data))
      if (err.response.data.detail) {
        toast.info(err.response.data.detail, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      setLoading && setLoading(false)
    })
}
export const likeProduct = (formState) => async (dispatch) => {
  axiosInstance
    .put(`/products/${formState.id}/like/`, formState)
    .then((res) => {
      console.log(res.data)
      // if(formState.like){
      //   setIsLiked(true)
      //   setLikes((prevLikes) => prevLikes + 1)
      // } else {
      //   setIsLiked(false)
      //   setLikes((prevLikes) => prevLikes - 1)
      // }
    })
    .catch((err) => {
      console.log(rr.response.data)
      dispatch(slice.actions.updateProduct_error(err.response.data))
    })
}

export const deleteProduct = (product) => async (dispatch) => {
  axiosInstance
    .delete(`/products/${product.id}/`, product)
    .then((res) => {
      dispatch(slice.actions.deleteProduct(product))
    })
    .catch((err) => {
      dispatch(slice.actions.deleteProductFail(err.response.data))
    })
}

export const getLikedBy = (product, setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/products/${product.id}/likes/`)
    .then((res) => {
      console.log(res.data)
      dispatch(slice.actions.getLikedBy(res.data))
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtist_error(err.response.data))
    })
}

export default slice
