import WithLayout from "@/views/layouts/WithLayout"
import React, { lazy, Suspense } from "react"
import { Main, Minimal } from "./views/layouts/layouts"

//<Suspense fallback={<LoadingScreen />}>
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  )

// Users
const FeedView = Loadable(lazy(() => import("@/views/Users/Feed/Feed")))

// Orders
const ListOrderView = Loadable(lazy(() => import("@/views/Orders/List/List")))

// Nfts
const ListNFTView = Loadable(lazy(() => import("@/views/Nfts/List/List")))
const ShowNFTView = Loadable(lazy(() => import("@/views/Nfts/Show/Show")))

// Transactions
const NewTransactionView = Loadable(lazy(() => import("@/views/Transactions/Create")))

// Products
const ListProductView = Loadable(lazy(() => import("@/views/Products/List/Home")))
const NewProductView = Loadable(lazy(() => import("@/views/Products/Create/Create")))
const ShowProductView = Loadable(lazy(() => import("@/views/Products/Show/Show")))

// Artists
const ShowArtistView = Loadable(lazy(() => import("@/views/Artists/Show/Show")))
const ListArtistView = Loadable(lazy(() => import("@/views/Artists/List/List")))

// Auth
const PasswordResetCoverView = Loadable(lazy(() => import("@/views/Auth/PasswordResetCover")))
const PasswordResetView = Loadable(lazy(() => import("@/views/Auth/PasswordReset")))
const SigninSimpleView = Loadable(lazy(() => import("@/views/Auth/Signin")))
const SignupSimpleView = Loadable(lazy(() => import("@/views/Auth/Signup")))
const BecomeAnArtistView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/BecomeAnArtist")))
const VerifyEmailView = Loadable(lazy(() => import("@/views/Auth/VerifyEmail")))
const SettingsView = Loadable(lazy(() => import("@/views/Auth/Settings")))

// Pages
const ContactPageView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/ContactPage")))
const AboutView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/About")))
const CompanyTermsView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/CompanyTerms")))
const PrivacyPolicyView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/PrivacyPolicy")))
const ArtistsDeclarationView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/ArtistsDeclaration")))
const GuidanceForBuyersView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/GuidanceForBuyers")))
const PricingView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/Pricing")))
const ReferralsView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/Referrals")))
const ContactPageSidebarMapView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/ContactPageSidebarMap")))
const ContactPageCoverView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/ContactPageCover")))
const AboutSideCoverView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/AboutSideCover")))
const NotFoundView = Loadable(lazy(() => import("@/views/Pages/ErrorPages/NotFound")))
const NotFoundCoverView = Loadable(lazy(() => import("@/views/Pages/ErrorPages/NotFoundCover")))
const KnowledgeBaseView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/KnowledgeBase")))
const FAQView = Loadable(lazy(() => import("@/views/Pages/CompanyPages/FAQ")))

// Layout Options
var secondaryBackgroundColor = "rgba(245,245,246,255)"
var yPadding = "64px 0"

const routes = [
  {
    path: "/",
    element: <WithLayout component={ListProductView} layout={Main} padding={yPadding} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/search",
    element: <WithLayout component={ListProductView} layout={Main} padding={yPadding} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/artworks/new",
    element: <WithLayout component={NewProductView} layout={Main} backgroundColor={secondaryBackgroundColor} privateRoute={true} />,
  },
  {
    path: "/artworks/:productId",
    element: <WithLayout component={ShowProductView} layout={Main} backgroundColor={"#FCFCFC"} />,
  },
  // So the old links work:
  {
    path: "/products/:productId",
    element: <WithLayout component={ShowProductView} layout={Main} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/artists",
    element: <WithLayout component={ListArtistView} layout={Main} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/artists/:handle",
    element: <WithLayout component={ShowArtistView} layout={Main} backgroundColor={secondaryBackgroundColor} />,
  },
  {
    path: "/feed",
    element: <WithLayout component={FeedView} layout={Main} backgroundColor={secondaryBackgroundColor} />,
  },
  {
    path: "/wallet",
    element: <WithLayout component={ListNFTView} layout={Main} privateRoute={true} />,
  },
  {
    path: "/nfts/:nftId",
    element: <WithLayout component={ShowNFTView} layout={Main} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/transactions/new",
    element: <WithLayout component={NewTransactionView} layout={Main} privateRoute={true} />,
  },
  {
    path: "/dashboard",
    element: <WithLayout component={ListOrderView} layout={Main} privateRoute={true} />,
  },
  // {
  //   path: "/profile/:userId",
  //   element: <WithLayout component={ProfileView} layout={Main} privateRoute={true} />,
  // },
  {
    path: "/settings",
    element: <WithLayout component={SettingsView} layout={Main} privateRoute={true} backgroundColor={secondaryBackgroundColor} />,
  },
  {
    path: "/contact-us",
    element: <WithLayout component={ContactPageView} layout={Main} />,
  },
  {
    path: "/about",
    element: <WithLayout component={AboutView} layout={Main} />,
  },
  {
    path: "/terms-of-service",
    element: <WithLayout component={CompanyTermsView} layout={Main} />,
  },
  {
    path: "/privacy-policy",
    element: <WithLayout component={PrivacyPolicyView} layout={Main} />,
  },
  {
    path: "/artists-declaration",
    element: <WithLayout component={ArtistsDeclarationView} layout={Main} />,
  },
  {
    path: "/guidance-for-buyers",
    element: <WithLayout component={GuidanceForBuyersView} layout={Main} />,
  },
  {
    path: "/pricing",
    element: <WithLayout component={PricingView} layout={Main} />,
  },
  {
    path: "/referrals",
    element: <WithLayout component={ReferralsView} layout={Main} privateRoute={true} />,
  },
  {
    path: "/contact-sidebar-map",
    element: <WithLayout component={ContactPageSidebarMapView} layout={Main} />,
  },
  {
    path: "/contact-us-cover",
    element: <WithLayout component={ContactPageCoverView} layout={Main} />,
  },
  {
    path: "/about-side-cover",
    element: <WithLayout component={AboutSideCoverView} layout={Main} />,
  },
  {
    path: "/knowledge-base",
    element: <WithLayout component={KnowledgeBaseView} layout={Main} />,
  },
  {
    path: "/FAQ",
    element: <WithLayout component={FAQView} layout={Main} />,
  },
  {
    path: "/password-reset-cover",
    element: <WithLayout component={PasswordResetCoverView} layout={Minimal} />,
  },
  {
    path: "/password-reset",
    element: <WithLayout component={PasswordResetView} layout={Minimal} />,
  },
  {
    path: "/signin",
    element: <WithLayout component={SigninSimpleView} layout={Minimal} />,
  },
  {
    path: "/become-a-tpg-artist",
    element: <WithLayout component={BecomeAnArtistView} layout={Minimal} />,
  },
  {
    path: "/signup",
    element: <WithLayout component={SignupSimpleView} layout={Minimal} />,
  },
  {
    path: "/invite",
    element: <WithLayout component={SignupSimpleView} layout={Minimal} />,
  },
  {
    path: "/users/email-verification",
    element: <WithLayout component={VerifyEmailView} layout={Minimal} />,
  },
  {
    path: "*",
    element: <WithLayout component={NotFoundCoverView} layout={Minimal} />,
  },
]

export default routes
