import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
import { useSelector, useDispatch } from "@/reducerStore"

export default function usePageTracking() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  var { user, isAuthenticated } = useSelector((state) => state.user)

  useEffect(() => {
    // console.log("user", user)
    if (!window.location.href.includes("localhost") && user) {
      try {
        // console.log("initialize")
        // console.log(user?.id)

        ReactGA.initialize([
          {
            trackingId: "G-TMX0T3CBL9",
            debug: window.location.href.includes("localhost"),
            titleCase: false,
            gaOptions: {
              userId: user?.id,
            },
          },
        ])
        setInitialized(true)
      } catch (err) {
        console.error("ga-err", err)
      }
    }
  }, [user])

  useEffect(() => {
    // if (initialized && !window.location.href.includes("localhost")) {
    // console.log("initialized", initialized, ReactGA)
    if (initialized && ReactGA && !window.location.href.includes("localhost")) {
      // console.log("track!")
      // ReactGA.send("pageview")
      // console.log({ hitType: "pageview", page: location.pathname + location.search })
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: document.title.replace(/[(0-9)]+ /, "") })
    }
  }, [initialized, location])
}
