import Avatar from "@mui/material/Avatar";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(src, name, sx) {
  if(src){
    return {sx: { ...sx }}
  }
  if(name){
    return {
      sx: {
        bgcolor: stringToColor(name),
        ...sx,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[name.split(' ').length-1][0]}`,
    };
  } else {
    return null
  }
}

export default function TPGAvatar({src, name, sx, ...other}) {
  return (
    <Avatar src={src} {...stringAvatar(src, name, sx)} {...other} style={{textDecoration: "none"}}/>
  )
}