import SearchIcon from "@mui/icons-material/Search"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/styles"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

function SearchBox({ miniSearchActive, setMiniSearchActive }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: { search: searchParams.get("search") || "" },
    touched: {},
    errors: {},
  })

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log("handleSubmit")
    if (formState.values.search) {
      navigate(`/search?q=${formState.values.search}`)
    } else {
      navigate(`/`)
    }
  }
  return (
    <Paper
      key="search1"
      component="form"
      onSubmit={handleSubmit}
      elevation={0}
      // variant="outlined"
      style={{
        height: "49px",
        boxShadow: "inset 0 0 4px #EEE, 3px 3px 5px 0 #FFF",
        // boxShadow: "inset 0 1px 2px #eee",
        border: !miniSearchActive ? "0px solid #EEE" : "1px solid #EEE",
        flexBasis: "100%",
        borderRadius: !miniSearchActive ? "6px" : "40px",
      }}
      sx={{ p: 0, display: "flex", alignItems: "center", maxWidth: 400 }}
    >
      <TextField
        id="outlined-search"
        key="search"
        // label="Search field"
        // type="search"
        placeholder="Search"
        name="search"
        value={formState.values.search}
        onChange={handleChange}
        onSubmit={handleSubmit}
        inputProps={{
          style: {
            paddingLeft: 12,
          },
        }}
        style={{ width: "100%" }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "none",
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <>
              {/* <Menu>
                <MenuItem style={{ fontSize: "20px" }} primaryText="Search By" />
                <MenuItem value={1} style={{ fontSize: "20px" }} primaryText="First Name" />
                <MenuItem value={1} style={{ fontSize: "20px" }} primaryText="Last Name" />
              </Menu> */}
            </>
          ),
        }}
      />
      <InputAdornment position="end">
        <IconButton type="submit" sx={{ p: "10px", pr: "12px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    </Paper>
  )
}

export default function SearchBar({ miniSearchActive, setMiniSearchActive }) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  })

  function MiniSearch({ miniSearchActive, setMiniSearchActive }) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        {!miniSearchActive ? (
          <IconButton type="submit" style={{ float: "right" }} sx={{ p: "10px" }} aria-label="search" onClick={() => setMiniSearchActive((prevState) => !prevState)}>
            <SearchIcon />
          </IconButton>
        ) : (
          <SearchBox miniSearchActive={miniSearchActive} setMiniSearchActive={setMiniSearchActive} />
        )}
      </div>
    )
  }

  return isSm ? (
    <SearchBox miniSearchActive={miniSearchActive} setMiniSearchActive={setMiniSearchActive} key="search1" />
  ) : (
    <MiniSearch miniSearchActive={miniSearchActive} setMiniSearchActive={setMiniSearchActive} />
  )
}
