import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
}))

export default function Popup({ title, dialogAction, children, openPopup, setOpenPopup, noDividers }) {
  const classes = useStyles()

  return (
    <Dialog open={openPopup} fullWidth maxWidth="lg" classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle classes={classes.DialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {setOpenPopup && (
            <Button color="primary" onClick={() => setOpenPopup(false)}>
              <CloseIcon />
            </Button>
          )}
        </div>
      </DialogTitle>
      {dialogAction ? (
        <DialogContent dividers>{children}</DialogContent>
      ) : (
        <>
          {!noDividers && <Divider />}
          <DialogContent>{children}</DialogContent>
        </>
      )}
      {dialogAction && <DialogActions>{dialogAction}</DialogActions>}
    </Dialog>
  )
}
