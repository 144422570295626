import { useDispatch, useSelector } from "@/reducerStore"
import { connectWallet } from "@/slices/users"
import { Button, Typography } from "@mui/material"
import React from "react"

export default function NamiButton() {
  var { user, isNamiConnected, errors } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  if (!user) {
    return <div />
  } else {
    return isNamiConnected ? (
      <>
        <Typography color="success.main">Nami Wallet Connected</Typography>
        {/* <Button variant="outlined" color="success" disableTouchRipple aria-disabled>
          Nami Connected
        </Button> */}
      </>
    ) : (
      <Button variant="contained" onClick={() => dispatch(connectWallet())}>
        Connect Nami
      </Button>
    )
  }
}
