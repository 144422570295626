import { socialLinks } from "@/data/links"

export const pages = {
  landings: {
    title: "Landings",
    id: "landing-pages",
    children: {
      services: {
        groupTitle: "Services",
        pages: [
          {
            title: "Coworking",
            href: "/coworking",
          },
          {
            title: "Rental",
            href: "/rental",
          },
          {
            title: "Job Listing",
            href: "/job-listing",
          },
          {
            title: "E-Learning",
            href: "/e-learning",
          },
          {
            title: "E-commerce",
            href: "/e-commerce",
          },
          {
            title: "Expo",
            href: "/expo",
          },
        ],
      },
      apps: {
        groupTitle: "Apps",
        pages: [
          {
            title: "Desktop App",
            href: "/desktop-app",
          },
          {
            title: "Mobile App",
            href: "/mobile-app",
          },
        ],
      },
      web: {
        groupTitle: "Web",
        pages: [
          {
            title: "Marketing",
            href: "/",
          },
          {
            title: "Overview",
            href: "/home",
          },
          {
            title: "Basic",
            href: "/web-basic",
          },
          {
            title: "Service",
            href: "/service",
          },
          {
            title: "Startup",
            href: "/startup",
          },
          {
            title: "Enterprise",
            href: "/enterprise",
          },
          {
            title: "Cloud Hosting",
            href: "/cloud-hosting",
          },
          {
            title: "Agency",
            href: "/agency",
          },
          {
            title: "Design Company",
            href: "/design-company",
          },
          {
            title: "Logistics",
            href: "/logistics",
          },
        ],
      },
    },
  },
  pages: {
    title: "Pages",
    id: "supported-pages",
    children: {
      career: {
        groupTitle: "Career",
        pages: [
          {
            title: "Lising",
            href: "/career-listing",
          },
          {
            title: "Lising Minimal",
            href: "/career-listing-minimal",
          },
          {
            title: "Opening",
            href: "/career-opening",
          },
        ],
      },
      helpCenter: {
        groupTitle: "Help center",
        pages: [
          {
            title: "Overview",
            href: "/help-center",
          },
          {
            title: "Article",
            href: "/help-center-article",
          },
        ],
      },
      company: {
        groupTitle: "Company",
        pages: [
          {
            title: "About",
            href: "/about",
          },
          {
            title: "Pricing",
            href: "/pricing",
          },
          // {
          //   title: "Refer a Friend",
          //   href: "/referrals",
          // },

          // {
          //   title: "Artist's Declaration",
          //   href: "/artists-declaration",
          // },
          // {
          //   title: "Guidance For Buyers",
          //   href: "/guidance-for-buyers",
          // },
        ],
      },
      contact: {
        groupTitle: "Contact",
        pages: [
          {
            title: "Contact Us",
            href: "/contact-us",
          },
          // {
          //   title: "Refunds & Cancellations", // I should turn this into a page on "Buyer's guide"
          //   href: "/contact-us",
          // },
          {
            title: "Terms of Service",
            href: "/terms-of-service",
          },
          {
            title: "Privacy Policy",
            href: "/privacy-policy",
          },
          // {
          //   title: "What are NFTs?",
          //   href: "https://blog.thepixelgallery.co/what-do-nfts-mean-for-art-fans-buyers-and-collectors/",
          //   external: "true",
          // },
          {
            title: "How The Pixel Gallery Works",
            href: "/become-a-tpg-artist",
          },
        ],
      },
      blog: {
        groupTitle: "Explore",
        pages: [
          // {
          //   title: "Community",
          //   href: socialLinks.community,
          //   external: true,
          // },
          // {
          //   title: "Blog",
          //   href: socialLinks.blog,
          //   external: true,
          // },
          {
            title: "FAQs",
            href: "/faq",
          },
          // {
          //   title: "Knowledge Base",
          //   href: "/knowledge-base",
          // },
          // {
          //   title: "Medium",
          //   href: socialLinks.medium,
          //   external: true,
          // },
        ],
      },
      portfolio: {
        groupTitle: "Portfolio",
        pages: [
          {
            title: "Basic",
            href: "/portfolio-page",
          },
          {
            title: "Masonry",
            href: "/portfolio-masonry",
          },
          {
            title: "Grid View",
            href: "/portfolio-grid",
          },
          {
            title: "Parallax Effect",
            href: "/agency",
          },
        ],
      },
    },
  },
  account: {
    title: "Account",
    id: "account",
    children: {
      settings: {
        groupTitle: "Settings",
        pages: [
          {
            title: "General",
            href: "/account/?pid=general",
          },
          {
            title: "Security",
            href: "/account/?pid=security",
          },
          {
            title: "Notifications",
            href: "/account/?pid=notifications",
          },
          {
            title: "Billing",
            href: "/account/?pid=billing",
          },
        ],
      },
      signup: {
        groupTitle: "Sign up",
        pages: [
          {
            title: "Simple",
            href: "/signup",
          },
          {
            title: "Cover",
            href: "/signup-cover",
          },
        ],
      },
      signin: {
        groupTitle: "Sign in",
        pages: [
          {
            title: "Simple",
            href: "/signin-simple",
          },
          {
            title: "Cover",
            href: "/signin-cover",
          },
        ],
      },
      password: {
        groupTitle: "Password reset",
        pages: [
          {
            title: "Simple",
            href: "/password-reset-simple",
          },
          {
            title: "Cover",
            href: "/password-reset-cover",
          },
        ],
      },
      error: {
        groupTitle: "Error",
        pages: [
          {
            title: "Simple",
            href: "/not-found",
          },
          {
            title: "Cover",
            href: "/not-found-cover",
          },
        ],
      },
    },
  },
}
