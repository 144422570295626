import { combineReducers } from "@reduxjs/toolkit"
import { reducer as artistReducer } from "../slices/artists"
import { reducer as commentReducer } from "../slices/comments"
import { reducer as imageReducer } from "../slices/images"
import { reducer as nftReducer } from "../slices/nfts"
import { reducer as notificationReducer } from "../slices/notifications"
import { reducer as orderReducer } from "../slices/orders"
import { reducer as pricingReducer } from "../slices/pricing"
import { reducer as productReducer } from "../slices/products"
import { reducer as statisticsReducer } from "../slices/statistics"
import { reducer as transactionReducer } from "../slices/transactions"
import { reducer as userReducer } from "../slices/users"

const rootReducer = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  artists: artistReducer,
  images: imageReducer,
  products: productReducer,
  comments: commentReducer,
  nfts: nftReducer,
  orders: orderReducer,
  pricing: pricingReducer,
  transactions: transactionReducer,
  statistics: statisticsReducer,
})

export default rootReducer
