import { useDispatch } from "@/reducerStore"
import { logout } from "@/slices/users"
import TPGAvatar from "@/utils/TPGAvatar"
import { Dashboard, Logout, Settings } from "@mui/icons-material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import React from "react"
import { isEdge, isIE, isIOS, isSafari } from "react-device-detect"
import { Link } from "react-router-dom"

export default function UserMenu({ user }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const dispatch = useDispatch()
  // Menu handlers
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <Box sx={{ flexGrow: 0, px: 2 }}>
      <Tooltip title="Open User Menu">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <TPGAvatar
            name={user?.artist?.name || user?.email}
            src={isIE || isSafari || isEdge || isIOS ? user?.artist?.profile_picture_current?.image.lg_jpeg : user?.artist?.profile_picture_current?.image.lg}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem component={Link} to={`/artists/${user?.artist.handle}`}>
          <TPGAvatar name={user?.artist?.name || user?.email} src={user?.artist.profile_picture_current?.image.original} />
          <ListItemText primary={user?.artist.name ? user?.artist.name : user?.email} secondary={user?.artist.name && user?.email} />
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to="/dashboard">
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          Dashboard
        </MenuItem>
        <MenuItem component={Link} to="/settings">
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => dispatch(logout())}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}
