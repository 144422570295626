import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export default function storeTPGReferralCode() {
  const [searchParams, setSearchParams] = useSearchParams()
  const trackingParameter = "ref"
  const [tracking_id, setTracking_id] = useState(searchParams.get(trackingParameter))

  useEffect(() => {
    // Remove the param
    if (searchParams.has(trackingParameter)) {
      // searchParams.delete(trackingParameter)
      setSearchParams(searchParams)
    }
  }, [])

  if (searchParams.has(trackingParameter)) {
    localStorage.setItem("referral_code", searchParams.get(trackingParameter))
  }
}
