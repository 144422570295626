import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import { useMediaQuery, Divider } from "@mui/material"
import { Topbar, Footer, Sidebar } from "./components"
import { pages } from "@/data/pages"
import { ToastContainer } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}))

const Main = ({ children, themeMode, backgroundColor, padding }) => {
  const classes = useStyles()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const open = isMd ? false : openSidebar

  return (
    <div className={clsx({ [classes.root]: true })}>
      <ToastContainer />
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} />
      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" pages={pages} />
      <main
        style={{
          padding: padding,
          backgroundColor: backgroundColor,
        }}
      >
        {children}
      </main>
      <Footer pages={pages} />
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  themeMode: PropTypes.string.isRequired,
}

export default Main
