export const images = {
  thePixelGallery: {
    icon: "",
    logo: "",
    bannerLight: "/images/SVG/PG_logo_black.svg", // Don't use PNGs for the logo, bc scaling them is a PITA
    // bannerLight: "/images/SVG/PG_logo_black_2.png",
    bannerDark: "/images/SVG/PG_logo_white_2.svg",
    // bannerDark: "/images/SVG/PG_logo_white_2.png",
  },
  trustBadges: {
    trustBadgeVersion: 2,
    trustBadge1: "/images/trust_badges/trust_badge_1.png",
    trustBadge2: "/images/trust_badges/trust_badge_2.png",
    trustBadge3: "/images/trust_badges/trust_badge_3.png",
    trustBadge4: "/images/trust_badges/trust_badge_4.png",
    trustBadge5: "/images/trust_badges/trust_badge_5.png",
    card_icons: {
      amex: "/images/trust_badges/card_icons/amex.svg",
      diners: "/images/trust_badges/card_icons/diners.svg",
      discover: "/images/trust_badges/card_icons/discover.svg",
      mastercard: "/images/trust_badges/card_icons/mastercard.svg",
      unionpay: "/images/trust_badges/card_icons/unionpay.svg",
      visa: "/images/trust_badges/card_icons/visa.svg",
    },
  },
  poweredBy: {
    cardano: "/images/external_logos/cardano.png",
    ipfs: "/images/external_logos/ipfs.png",
    nami: "/images/external_logos/nami.svg",
  },
}
