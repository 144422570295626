import Loader from "@/components/custom/Loader"
import { Icon } from "@/components/thefront/atoms"
import { useDispatch, useSelector } from "@/reducerStore"
import { get_notifications, mark_all_notifications_as_read, showUnreadCount } from "@/slices/notifications"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { Avatar, Badge, Box, colors, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, Tooltip, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useRef, useState } from "react"
import { isEdge, isIE, isIOS, isSafari } from "react-device-detect"
import { Link as RouterLink } from "react-router-dom"

const now = new Date()

const useStyles = makeStyles((theme) => ({
  myButtonClass: {
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
}))

// const notifications = [
//   {
//     id: "5e8883f1b51cc1956a5a1ec0",
//     createdAt: subHours(now, 2).getTime(),
//     description: "Dummy text",
//     title: "Your order is placed",
//     type: "order_placed",
//   },
//   {
//     id: "5e8883f7ed1486d665d8be1e",
//     createdAt: subDays(now, 1).getTime(),
//     description: "You have 32 unread messages",
//     title: "New message received",
//     type: "new_message",
//   },
//   {
//     id: "5e8883fca0e8612044248ecf",
//     createdAt: subDays(now, 3).getTime(),
//     description: "Dummy text",
//     title: "Your item is shipped",
//     type: "item_shipped",
//   },
//   {
//     id: "5e88840187f6b09b431bae68",
//     createdAt: subDays(now, 7).getTime(),
//     description: "You have 32 unread messages",
//     title: "New message received",
//     type: "new_message",
//   },
// ]

export default function NotificationsMenu({}) {
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  var { user, errors } = useSelector((state) => state.user)
  var { unread_count, notifications, errors } = useSelector((state) => state.notification)

  React.useEffect(() => {
    dispatch(showUnreadCount())
  }, [])

  const handleOpen = () => {
    dispatch(get_notifications())
    setOpen(true)
    if (unread_count > 0) {
      dispatch(mark_all_notifications_as_read())
      dispatch(showUnreadCount())
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const renderMessage = (notification) => {
    if (notification.recipient?.id === notification.action_object?.id && notification.actor?.id === notification.action_object?.id && notification.actor?.id === notification.recipient?.id) {
      // New Message
      return notification.verb
    } else {
      var actorName = notification.actor.artist?.id === user.artist?.id ? "You" : notification.actor.artist.name
      var verb = notification.verb
      var targetName
      switch (notification.action_object_content_type) {
        case "comments | comment":
          targetName = notification.target?.title
          break
        case "products | like":
          console.log("hellz")
          targetName = notification.target?.title
          break
        case "products | product":
          targetName = notification.target?.title
          break
        case "artists | artist following":
          targetName = `You`
          break
        case "orders | nft":
          targetName = notification.target?.product?.title
          break
        case "users | new user":
          targetName = ``
          break
      }
      return `${actorName} ${verb} ${targetName}`
    }
  }
  const renderLink = (notification) => {
    if (notification === undefined || notification === null) {
      return {
        link: null,
        target: null,
      }
    }
    if (notification.data?.link) {
      // New Message
      return {
        href: notification.data.link,
        target: "_blank",
        component: "a",
      }
    }

    var link
    switch (notification.action_object_content_type) {
      case "comments | comment":
        link = ""
        break
      case "products | like":
        link = `/artworks/${notification.target?.id}`
        break
      case "products | product":
        link = `/artworks/${notification.target?.id}`
        break
      case "artists | artist following":
        link = `/artists/${notification.actor.artist.handle}`
        break
      case "orders | nft":
        link = `/nfts/${notification.target?.id}`
        break
      case "users | new user":
        link = `/artists/${user.artist.handle}`
        break
    }

    return {
      link: link,
      target: null,
      component: RouterLink,
    }
  }
  const renderIcon = (notification) => {
    switch (notification.action_object_content_type) {
      case "comments | comment":
        return (
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            }}
          >
            <Icon fontIconClass="fas fa-comment" fontIconColor={colors.grey[0]} className="card-job-minimal__arrow" />
          </Avatar>
        )
      case "products | like":
        return (
          <Avatar sx={{ backgroundColor: "#CC3D5C", color: "primary.contrastText" }}>
            <Icon fontIconClass="fas fa-heart" fontIconColor={colors.grey[0]} className="card-job-minimal__arrow" />
          </Avatar>
        )
      case "products | product":
        return (
          <ListItemAvatar>
            <Avatar
              alt={notification?.action_object?.title}
              src={isIE || isSafari || isEdge || isIOS ? notification?.action_object?.thumbnail?.image?.md_jpeg : notification?.action_object?.thumbnail?.image?.md}
            />
          </ListItemAvatar>
        )
      case "artists | artist following":
        return (
          <ListItemAvatar>
            <Avatar alt={notification.actor.artist.name} src={`${notification.actor.artist.profile_picture_current?.image.original}?lastmod=${new Date().getTime()}`} />
          </ListItemAvatar>
        )
      case "orders | nft":
        return (
          <ListItemAvatar
            sx={{
              // backgroundColor: "primary.main",
              color: "primary.contrastText",
            }}
          >
            <Avatar
              alt={notification?.action_object?.product?.title}
              src={isIE || isSafari || isEdge || isIOS ? notification?.action_object?.product?.thumbnail?.image?.md_jpeg : notification?.action_object?.product?.thumbnail?.image?.md}
            />
          </ListItemAvatar>
        )
      case "users | new user":
        if (notification.data?.image) {
          return (
            <ListItemAvatar>
              <Avatar alt={notification?.action_object?.title} src={notification?.data?.image} />
            </ListItemAvatar>
          )
        } else {
          return (
            <Avatar sx={{ backgroundColor: "#CC3D5C", color: "primary.contrastText" }}>
              <Icon fontIconClass="fas fa-heart" fontIconColor={colors.grey[0]} className="card-job-minimal__arrow" />
            </Avatar>
          )
        }
      //          cart: <ShoppingCartIcon fontSize="small" />,
      //  order_placed: <CreditCardIcon fontSize="small" />,
    }
  }

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={unread_count}>
            <NotificationsIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        {!notifications ? (
          <Loader style={{ height: "200px" }} />
        ) : notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              You have no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map((notification, i) => {
                console.log(notification)
                // Don't show the notification if the action_object is deleted
                return (
                  notification?.target && (
                    <ListItem
                      divider
                      key={i}
                      component={renderLink(notification).component}
                      to={renderLink(notification)?.link}
                      href={renderLink(notification)?.href}
                      target={renderLink(notification)?.target}
                      className={classes.myButtonClass}
                    >
                      <ListItemAvatar>
                        {!notification.actor?.artist ? (
                          <Avatar
                            alt={notification.actor.artist.name}
                            src={isIE || isSafari || isEdge || isIOS ? notification.actor.artist.profile_picture_current?.image.sm_jpeg : notification.actor.artist.profile_picture_current?.image.sm}
                          />
                        ) : (
                          notification && renderIcon(notification)
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography color="textPrimary" sx={{ cursor: "pointer" }} underline="none" variant="subtitle2">
                            {renderMessage(notification)}
                          </Typography>
                        }
                        secondary={notification.description}
                      />
                    </ListItem>
                  )
                )
              })}
            </List>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button color="primary" size="small" variant="text">
                Mark all as read
              </Button>
            </Box> */}
          </>
        )}
      </Popover>
    </>
  )
}
