import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

export default function Loader({ ...other }) {
  return (
    <Box sx={{ display: "flex", height: "600px", alignItems: "center", width: "100%", justifyContent: "center" }} {...other}>
      <CircularProgress />
    </Box>
  )
}
